import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import UniversalFullWidthWrapper from 'components/HOC/UniversalFullWidthWrapper';
import RichPost from 'components/richText';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const jetCardRegex = /jet-card/

const Other = ({ data, preview, location, pageContext }) => {
  let slices = [];
  let pilot;
  let phoneNumber;

  const content = preview ? data.prismicOther.data.data : data.prismicOther;
  const language = preview ? content.lang : pageContext.lang;
  const alternateLanguages = preview ? content.alternate_languages : pageContext.alternateLanguages
  const uid = content.uid;

  const templateLayout = content.data.layout; 

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local, jet_card_phone_number}}} = filteredUniversalPhoneNumber;
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  const isNarrowBanner = data.prismicOther.data.url === "private-jet-charter/private-jet-charter" || data.prismicOther.data.url === "location-jet-prive";
  const isBlackBackgroundBanner = data.prismicOther.data.url === "contactUs/aviator-programme-contact" 
    || data.prismicOther.data.url === "contactUs/contact"
    || data.prismicOther.data.url === "contactUs/jet-card-contact"
    || data.prismicOther.data.url === "contactez-nous/jet-card-contactez"
    || data.prismicOther.data.url === "contactez-nous/contactez"
    || data.prismicOther.data.url === "contactez-nous/programme-aviator-contactez"

  //check if a document is part of jet-card to set static phone number
  if (jetCardRegex.test(uid)) {
    jet_card_phone_number ? phoneNumber = jet_card_phone_number : phoneNumber = phone_local
  } else {
    phoneNumber = phone_local
  }

  //lang codes for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  if (content) {
    slices = content.data.body1;
    pilot = content && content.data.body1 ? content.data.body1[1] : false;
  }

  const heroImage = content.data.hero_image
  const richContent = preview && content.data.post.length > 0 ? content.data.post : content.data.post.raw
  const hasWidget = slices ? slices.filter(el => el.slice_type === 'widget') : [];
  const bannerImageSlice = slices && slices.find(el => el.slice_type === "banner_image")
  const tableOfContentsSlice = slices && slices.filter(el => el.slice_type === "table_of_contents");

  const bodyComponent = ( 
    <div style={templateLayout === "full-width" && {margin: '20px 0px'}}>
      <RichPost
        richContent={richContent}
        slices={slices} 
        preview={preview} 
        language={language}
      />
    </div>
  )

  let headData = {
    meta_title: content.data.meta_title,
    meta_description: content.data.meta_description,
    meta_keywords: content.data.meta_keywords,
  }

  // add open graph meta data if available
  if(content?.data?.body2?.length > 0) {
    const filteredOpenGraphData = content.data.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  const wrapperProps = { 
    heroImage: heroImage, 
    preview: preview, 
    content: content, 
    component: bodyComponent,
    meta: headData, 
    location:location, 
    pilot: pilot,
    language: language,
    alternateLanguages: alternateLanguages,
    errorMessages: data.allPrismicPropertiesErrorMessages.edges,
    icons: universalProps,
    languageCodes: languageCodes,
    localePhoneNumber: data.allPrismicUniversalPhoneNumber.edges,
    defaultBannerImage: defaultBannerImage,
    headerData: headerData,
    footerProps: footerProps,
    universalProps: universalProps,
    phoneNumber: phoneNumber,
    uid: uid,
    widget: preview ? data?.allPrismicWidgetData?.edges : hasWidget.length > 0 ? hasWidget : [],
    bannerImageSlice: bannerImageSlice,
    showFlightSearchWidget: false,
    flightSearchErrorMessages: errorMessages,
    sideBarProps: universalProps,
    tableOfContentsSlice: tableOfContentsSlice,
    isNarrowBanner: isNarrowBanner,
    isBlackBackgroundBanner: isBlackBackgroundBanner
  }

  if (templateLayout === "full-width" ) {
    return <UniversalFullWidthWrapper {...wrapperProps} />

  } else {
    return <RenderPostWrapper {...wrapperProps} /> 

  }

}

export default Other

export const query = graphql`
  query OtherQuery($id: String!) {
    prismicOther(id: { eq: $id }) {
      ...Other
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`

Other.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}
