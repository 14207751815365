import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import SEO from "SEO";

import Banner from 'components/banner';
import ContentNavigation from 'components/navigation';
import HomepageFlightSearch from 'components/flightSearch/container';
import Layout from "components/layout";

import RenderRichText from 'utils/richText/renderRichText';

import { GridRow, GridColumn, FullWidthContainer } from 'components/grid/styled';

import { setPhoneNumber } from 'state/actions/phoneNumber';

const UniversalFullWidthWrapper = props => {
  const { bannerImageSlice, heroImage, defaultBannerImage, preview, tableOfContentsSlice, isNarrowBanner, isBlackBackgroundBanner } = props;

  useEffect(() => {
    props.setPhoneNumber(props.phoneNumber)
  }, [])

  let bannerImageSrc;
  let bannerImageSize;
  let textPosition;
  let headingOneRichText;
  let headingTwoRichText;
  let headingThreeRichText;
  let paragraphRichText;

  if (bannerImageSlice) {
    const headingOneData = bannerImageSlice.primary.heading1;
    const headingTwoData = bannerImageSlice.primary.heading2;
    const headingThreeData = bannerImageSlice.primary.heading3;
    const paragraphData = bannerImageSlice.primary.banner_text;
    
    bannerImageSrc = bannerImageSlice.primary.image.url
    bannerImageSize = bannerImageSlice.primary.banner_image_size;
    textPosition = bannerImageSlice.primary.text_position;
    // check if banner heading 1-3 and paragraph data exists and is not empty
    headingOneRichText = headingOneData ? headingOneData.length > 0 && preview ? headingOneData : headingOneData.raw : [];
    headingTwoRichText = headingTwoData ? headingTwoData.length > 0 && preview ? headingTwoData : headingTwoData.raw : [];
    headingThreeRichText = headingThreeData ? headingThreeData.length > 0 && preview ? headingThreeData : headingThreeData.raw: [];
    paragraphRichText = paragraphData ? paragraphData.length > 0 && preview ? paragraphData : paragraphData.raw : [];

  } else if (heroImage && heroImage.url) {
    bannerImageSrc = heroImage.url
  } else {
    bannerImageSrc = defaultBannerImage.url
  }

  return (
    <Layout 
      location={props.location} 
      language={props.language}
      alternateLanguages={props.alternateLanguages}
      localePhoneNumber={props.localePhoneNumber}
      universalProps={props.universalProps}
      errorMessages={props.errorMessages}
      icons={props.icons}
      footerProps={props.footerProps}
      headerData={props.headerData}
      preview={props.preview}
      uid={props.uid}
      previewHeaderData={props.headerData}
      previewFooterData={props.footerProps.node}
    >
      <SEO 
        meta={props.meta} 
        location={props.location}
        language={props.language}
        alternateLanguages={props.preview ? [] : props.alternateLanguages}
        languageCodes={props.languageCodes}
        preview={props.preview}
      />

      <Banner 
        desktopLarge={false}
        desktopMedium={true}
        desktopSmall={false}
        isHomePage={false} 
        headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
        headingTwo={headingTwoRichText && <RenderRichText richTextArray={headingTwoRichText} />}
        headingThree={headingThreeRichText && headingThreeRichText && <RenderRichText richTextArray={headingThreeRichText} />}
        isTemplate={true}
        bannerImageSrc={bannerImageSrc}
        textPosition={textPosition}
        bannerImageSize={bannerImageSize}
        isNarrowBanner={isNarrowBanner}
        isBlackBackgroundBanner={isBlackBackgroundBanner}
      />

      { tableOfContentsSlice && tableOfContentsSlice.length > 0 && (
        <ContentNavigation 
          anchors={tableOfContentsSlice[0].items || tableOfContentsSlice } 
          isTwoColumnLayout={true}
          isAircraftTemplate={false}
        />  
      )}  
      <GridRow paddingBottom='30px' paddingTop='30px'>
        <GridColumn>
          {props.component}
        </GridColumn>
      </GridRow>
    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(UniversalFullWidthWrapper);

UniversalFullWidthWrapper.propTypes = {
  alternateLanguages: PropTypes.array,
  bannerImageSlice: PropTypes.object,
  component: PropTypes.object,
  defaultBannerImage: PropTypes.object,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  heroImage: PropTypes.object,
  icons: PropTypes.array,
  isBlackBackgroundBanner: PropTypes.bool,
  isNarrowBanner: PropTypes.bool,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  tableOfContentsSlice: PropTypes.array,
  uid: PropTypes.string,
  universalProps: PropTypes.array
};
